const tableObject = [
    {
        'Item': 'A',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'B',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'C',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'D',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'E',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'F',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'G',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'H',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'I',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'J',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'K',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'L',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'M',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'N',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'O',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'P',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'Q',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'R',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'S',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'T',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'U',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'V',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'W',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'X',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'Y',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
    {
        'Item': 'Z',
        "Occurence": 0,
        "Success": 0,
        'SuccessPct': 0,
        "Failure": 0,
        'FailurePct': 0,
    },
  ]

  export default tableObject;