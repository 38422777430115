import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { colors, Typography } from '@mui/material';
import './Info.css'


const useStyles = makeStyles({
    main_div:{
        padding: '10px',
        margin: '5px 5px 5px 5px',
        borderRadius: '10px',
        background: '#1a1a1a',
        color: 'white',
    }
})

const TABLE = createTheme({

    typography: {
    //"fontFamily": `'VT323', monospace`,
     "fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 12,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
 });

export default function Info() {

    const classes = useStyles();

   
    return (
        <div className={classes.main_div}>
            <ThemeProvider theme={TABLE}>

                <Typography>
                    This project is inspired by <a href='https://morse.withgoogle.com/learn/' target={'_blank'}>Morse-withgoogle</a>, please have a look at the google cheat sheet for morse, which simplifies the learning
                    by making use of real life objects.<br></br>
                    <a href='https://res.cloudinary.com/dguetook9/image/upload/v1678264770/Morse_code_cheat_sheet_gewhdz.webp' target={'_blank'}>Click Here!</a>
                </Typography>
                    
            </ThemeProvider>
        </div>
    
  )
}
