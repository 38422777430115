import './App.css';
import Code from './components/Code/Code';
import Decode from './components/Decode/Decode';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';

function App() {
  return (
    <div className="App">
      <div className='header'>
        <Header/>
      </div>
      <div className='body'>
        <Code/>
      </div>
      <div className='footer'>
        <Footer/>
      </div>  
        {/* <Decode/> */}
    </div>
  );
}

export default App;
