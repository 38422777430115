import React, { useEffect, useRef, useState } from "react";
import { CSSTransitionGroup } from 'react-transition-group'
import { makeStyles } from "@mui/styles";
import "./Code.css";
import wordList from "../../static/wordList";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import Paper from "@mui/material/Paper";
import Footer from "../Layout/Footer";
import CodeBlock from "../Layout/CodeBlock";
import LearnCode from "../Layout/LearnCode"
import Table from "../Table/Table";
import Header from "../Layout/Header";

import initialTableObject from '../../static/tableObject.js';
import legend from "../../static/legend";

const green_board_pressed_learn = '/assets/green_board_pressed_learn.png';
const green_board_pressed_practice = '/assets/green_board_pressed_practice.png';
const grey_board_learn = '/assets/grey_board_learn.png';
const grey_board_practice = '/assets/grey_board_practice.png';
const legend_black = '/assets/legend_new.png';
const legend_black_pressed = '/assets/legend_new_pressed.png';
const progress_new_black = '/assets/progress_new.png';
const progress_new_black_pressed = '/assets/progress_new_pressed.png';

const useStyles = makeStyles({

  main:{
    height: '100%'
  },
  code_main:{
    background: 'black',
    height: '100%',
    //height: 'calc(100vh - 100px)',
    paddingLeft: 100,
    paddingRight: 100,
    paddingTop: 0,
  },

  learn_switch:{
    maxWidth: '180px',
    width: '100%',
    margin: '5px',
    cursor: 'pointer'
  },

  practice_switch:{
    maxWidth: '180px',
    width: '100%',
    margin: '5px',
    cursor: 'pointer'
  },

  main_div:{
    border: '5px white solid',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '500px',
  },

  progress_button:{
    maxWidth: 110,
    position: 'relative',
    top: 0,
    right: 0,
    cursor: 'pointer',
    background: 'black',
  },

  legend_button:{
    maxWidth: 110,
    position: 'relative',
    top: 0,
    right: 0,
    cursor: 'pointer',
    background: 'black',
  },

  card_main: {
    flex: '1 1 auto'
  },

  card_sub: {
    background: '#1a1a1a',
    flex: '0 1 110px',
    padding: 10,
    borderRadius: 5,
  }
})

export default function Code() {
  const [text, setText] = useState("");
  const [learnFlag, setLearnFlag] = useState(false);
  const [practiceFlag, setPracticeFlag] = useState(true);
  const [progressFlag, setProgressFlag] = useState(false);
  const [legendFlag, setLegendFlag] = useState(false);
  const index = useRef(0);
  const state = useRef();
  const time = useRef(0);
  const checkTime = useRef(0);
  const buffer = useRef("");
  const word_count = 10;
  const classes = useStyles();
  const [tableContent, setTableContent] = useState(initialTableObject);
  var initialObject;

  //Function to create random text
  const generateText = () => {
    let str = "";
    let list = wordList.split(",");

    //Function to create random number b/w 0 and given number
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }

    for (let i = 0; i < word_count; i++) {
      if (i < word_count - 1) {
        console.log("1df");
        str += list[getRandomInt(1000)] + " ";
      } else {
        str += list[getRandomInt(1000)];
      }
    }
    setText(str);
  };

  const handleSwitchState = (e) =>{
    if(e === "learn"){
      setLearnFlag(!learnFlag);
      setPracticeFlag(!practiceFlag);
    }

    setLearnFlag(!learnFlag);
    setPracticeFlag(!practiceFlag);
  }

  const handleProgressButton = () =>{
    legendFlag && setLegendFlag(!legendFlag);
    setProgressFlag(!progressFlag);
  }

  const handleLegendButton = () =>{
    progressFlag && setProgressFlag(!progressFlag);
    setLegendFlag(!legendFlag);
  }

  var tableElement = true;
  const getObject = (object) => {
      console.log("entered", object, initialTableObject, tableContent);
      setTableContent(object);
      // if( tableContent === undefined){
      //   tableElement = false;
      // }
      // else{
      //   tableElement = true;
      // }
  }


  return (
    <div className={classes.main}>
    {/* <CssBaseline />
    {/* <Container maxWidth={false} disableGutters className={classes.code_main}>
      <CodeBlock text={text} generateText={() => generateText()} />
    </Container> */}
    
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.code_main}
    >
      
      <div className={classes.main_div}>
      
      <Card sx={{ maxWidth: 850, width: 850, minHeight: 480 , background: "#363535", color:' #39FF14', padding: '10px', margin: '5px', display: 'flex'}}>
        <div className={classes.card_main}>
        
          <img className={classes.practice_switch} src={practiceFlag? green_board_pressed_practice: grey_board_practice} onClick={()=>handleSwitchState("learn")}></img>
          <img className={classes.learn_switch} src={learnFlag? green_board_pressed_learn: grey_board_learn} onClick={()=>handleSwitchState("learn")}></img>

          { practiceFlag && <CodeBlock text={text} generateText={() => generateText()} />}
          { learnFlag && <LearnCode getObject={getObject}/>}
          {/* {
            tableElement ? <Table object={tableContent}/> : <Table object={initialTableObject} />
          } */}
        </div>
        <div className={classes.card_sub}>
          <img className={classes.progress_button} src={ progressFlag ?progress_new_black_pressed:progress_new_black} onClick={()=>handleProgressButton()}></img>
          <img className={classes.legend_button} src={ legendFlag ?legend_black_pressed:legend_black} onClick={()=>handleLegendButton()}></img>
        </div>
      </Card>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}>
      {progressFlag && 
          <Table key={0} className='example' object={tableContent} type='progress'/>
      }

      {
        legendFlag &&
        <Table key={0} className='example' object={legend} type='legend'/>
      }
       </CSSTransitionGroup>
      </div>
    </Box>
    

    </div>
  );
}
