import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import wordList from "../../static/wordList";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import Paper from "@mui/material/Paper";
import Footer from "./Footer";
import CodeBlock from "./CodeBlock";
import LearnCode from "./LearnCode"
import Table from "../Table/Table";

import initialTableObject from '../../static/tableObject.js';
import { Typography } from "@mui/material";

const green_board_pressed_learn = '/assets/green_board_pressed_learn.png';
const green_board_pressed_practice = '/assets/green_board_pressed_practice.png';
const grey_board_learn = '/assets/grey_board_learn.png';
const grey_board_practice = '/assets/grey_board_practice.png';

const logo = '/assets/Learn-morse-logo.png';

const useStyles = makeStyles({
    main_header: {
        background: 'black',
        color: 'white',
        padding: 50,
    },

    logo:{
        maxWidth: 500,
    }
})

const LOGO = createTheme({
    typography: {
     //"fontFamily": `'VT323', monospace`,
     "fontFamily": `"Oswald", sans-serif`,
     //"fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 48,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
 });

 const TAGLINE = createTheme({
    typography: {
     //"fontFamily": `'VT323', monospace`,
     //"fontFamily": `"Oswald", sans-serif`,
     "fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 18,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500,
    }
 });

 const CODE = createTheme({
    typography: {
     //"fontFamily": `'VT323', monospace`,
     "fontFamily": `"Oswald", sans-serif`,
     //"fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 18,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500,
    }
 });
export default function Header() {
  
  const classes = useStyles();

  return (
    <header className={classes.main_header}>
        {/* <Typography theme={LOGO} >
            LEARN-MORSE
            
        </Typography>
        <Typography theme={CODE} letterSpacing='15px' lineHeight='0px'>
            _____._.....
        </Typography> */}
        <img src={logo} className={classes.logo}></img>
        {/* <Typography theme={TAGLINE}>
            'Cause it's cool!
        </Typography> */}
    </header>
  );
}
