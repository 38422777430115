import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { colors, Typography } from '@mui/material';
import './Table.css'
import Info from '../Info/Info';

const useStyles = makeStyles({

    table: {
     
    },

    table_div:{
        maxWidth: '350px',
        maxHeight: '500px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: '#363535',
        margin: 5,
    },

    table_header:{
        width: '100%',
        position: 'sticky',
        top: 0,
        background: '#363535'
    },

    table_row:{
        width: '100%',
    },

    legend_header_div:{
        padding: '10px',
        boxSizing: 'border-box',
        borderRadius: '10px',
        background: '#1a1a1a',
        color: 'white',
    }
})

const TABLE = createTheme({

    typography: {
    //"fontFamily": `'VT323', monospace`,
     "fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 12,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
 });

export default function Table({object, type}) {

    const classes = useStyles();

    console.log(object)

    var list = type === 'progress' ?
    object.map( item => {
        return(
            <tr className={classes.table_row}>
                <td>{item.Item}</td>
                <td>{item.Occurence}</td>
                <td>{item.SuccessPct}</td>
                <td>{item.FailurePct}</td>
            </tr>
        )
    }):
    object.map( item => {
        return(
        
            <tr className={classes.table_row}>
                <td>{item.Item}</td>
                <td style={{ letterSpacing: '5px', paddingRight: '50px', paddingLeft: '50px'}}>{item.Code}</td>
            </tr>
          
        )
    })

    // const handleMouseEnter = () =>{

    //     console.log('fired Enter')
    //     let ele = document.getElementById('legend');

    //     ele.classList.remove('legend-leave');
    //     ele.classList.add('legend-enter');
        
    // }
    // const handleMouseLeave = () =>{

    //     console.log('fired Leave')
    //     let ele = document.getElementById('legend');

    //     ele.classList.remove('legend-enter');
    //     ele.classList.add('legend-leave');
    // }
    return (
        <div className={classes.table_div} id="legend">
            <ThemeProvider theme={TABLE}>

                { type === 'progress' ?
                    <>
                    <table className={classes.table}>

                   
                    <tbody>
                        
                    
                        <Typography>
        
                            <tr className={classes.table_header}>
                                <th>Item</th>
                                <th>Occurence</th>
                                <th>Success%</th>
                                <th>Failure%</th>
                            </tr> 
                    
                        
                                {list}
                        
                        </Typography>
                    </tbody> 
                </table>
                    </>
                :
                < div >

                <Info/>
                <table className={classes.table}>
                       
                    <tbody>
                        
                    
                        <Typography>
        
                                <tr className={classes.table_header}>
                                        <th >Item</th>
                                        <th >Code</th>
                                </tr>
                    
                                {list}
                        
                        </Typography>
                    </tbody> 
                </table>
                    </div>
                }
                

                
                </ThemeProvider>
        </div>
    
  )
}
