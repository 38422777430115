import { Box, Container,  } from "@mui/system";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Typography } from "@mui/material";

const useStyles = makeStyles({
    footer: {
        
        background: '#363535'
    }
})

const THEME = createTheme({
    typography: {
     "fontFamily": `'VT323', monospace`,
     "fontSize" : 25,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500,
    }
 });


export default function Footer(){

    const classes = useStyles();
    const footerNotice = `*** Press R or click on the ↩️ to reset the text *** *** Click or hold spacebar to enter Dit's and Dah's ***`;
    return(
        <Box sx={{background: '#363535', width: '100%'}}>
            <ThemeProvider theme={THEME}>
                <Typography sx={{ color: '#39FF14'}}>
                    <marquee>{footerNotice}</marquee>
                </Typography>
            </ThemeProvider>
            
        </Box>
            
        
    )
}