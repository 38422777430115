import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Table from "../Table/Table";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import { red } from '@mui/material/colors';

import initialTableObject from '../../static/tableObject';
const spaceImg = '/assets/spacebar_new.png';
const spaceImgPressed = '/assets/spacebar_new_pressed.png';
const resetImg = '/assets/reset_img.png';
const resetImgPressed = '/assets/reset_img_pressed.png';


const useStyles = makeStyles({
    heading:{
        // fontfamily: `'Rubik', sans-serif`,
        paddingTop: 20,
        paddingBottom: 10,
        fontSize: 38,
        fontWeight: '400',
        height: 110
    },

    code_field:{
        maxWidth: 700,
        minHeight: 50,
        paddingRight: '5%',
        paddingLeft: '5%',
        maxWidth: '700px',
        background:'black',
        color: 'white',
        borderRadius: 5,
        margin: 'auto',
        overflowWrap: 'break-word',
    },


    space_img:{
      aspectRatio: 'auto',
      maxWidth: '400px',
      width: '100%',
      //boxShadow: "0px 2px 10px white",
      verticalAlign: 'bottom'
    },

    space_img_pressed:{
      position: 'relative',
      aspectRatio: 'auto',
      maxWidth: '400px',
      width: '100%',
      //boxShadow: "0px 2px 10px white",
      verticalAlign: 'bottom'
    },

    reset_img:{
      maxWidth: '87px',
      width: '100%',
      verticalAlign: 'bottom'
    },

    keyboard:{
      display: 'inline-block',
      background: 'black',
    },

    
})

const TEXT = createTheme({
    typography: {
    "fontFamily": `'VT323', monospace`,
     //"fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 68,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
 });

const CODE = createTheme({
  typography: {
  "fontFamily": `'VT323', monospace`,
   //"fontFamily": `"Rubik", sans-serif`,
   "fontSize" : 28,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});


export default function LearnCode({getObject}) {

  console.log("Entered Learncode");

  const index = useRef(0);
  const state = useRef();
  const time = useRef(0);
  const checkTime = useRef(0);
  const [code, setCode] = useState([]);
  const buffer = useRef("");
  const word_count = 2;
  const classes = useStyles();
  const [spacePressed, setSpacePressed] = useState(false);
  const [resetPressed, setResetPressed] = useState(false);


  const legend = {
    A: "._",
    B: "_...",
    C: "_._.",
    D: "_..",
    E: ".",
    F: ".._.",
    G: "__.",
    H: "....",
    I: "..",
    J: ".___",
    K: "_._",
    L: "._..",
    M: "__",
    N: "_.",
    O: "___",
    P: ".__.",
    Q: "__._",
    R: "._.",
    S: "...",
    T: "_",
    U: ".._",
    V: "..._",
    W: ".__",
    X: "_.._",
    Y: "_.__",
    Z: "__..",
  };

  var [object, setObject] = useState(initialTableObject);

  const [text, setText] = useState("");

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const generateText = () => {

    console.log("Generate text");

    var sumOfOcc = 0;
    var sumOfAcc = 0;
    var averageOcc= 0;
    var averageAcc = 0;
    object.forEach( item => {

      sumOfOcc += item.Occurence;
      sumOfAcc += item.Success;

      item.SuccessPct = (item.Success/item.Occurence)*100 === NaN ? 0 :(item.Success/item.Occurence)*100;
      item.FailurePct = (item.Failure/item.Occurence)*100 === NaN ? 0 :(item.Failure/item.Occurence)*100;
    })

    averageOcc = sumOfOcc/26;
    averageAcc = sumOfAcc/26;

    var arrBasedOnOcc = [];
    var arrBasedOnAcc = [];

    arrBasedOnOcc = object.filter( item => {
      return item.Occurence <= averageOcc;
    })
    
    arrBasedOnAcc = arrBasedOnOcc.filter( item => {

      return item.Success <= averageAcc;
    })

    let randomText = arrBasedOnAcc[randomIntFromInterval(0, arrBasedOnOcc.length - 1)].Item;
    console.log(randomText)
    setText(randomText);
    console.log("Exit generatetext");
  }



  console.log("Before entering main useEffect");
  //Registering event listener and calling the generateText()
  useEffect(() => {

    console.log("Entered main useEffect")
    let keyDownEvent = document.addEventListener("keydown", handleKeyDown);
    let keyUpEvent = document.addEventListener("keyup", handleKeyUp);

    console.log("sending object - useEffect")
    //getObject(object);

    console.log("calling generate func - useEffect");
    generateText();

    return () => {
      document.removeEventListener("keydown", keyDownEvent);
      document.removeEventListener("keyup", keyUpEvent);
    };
  }, []);

  

  //Function to handle key down event
  //Stores the time when the key is pressed in ms
  const handleKeyDown = (e) => {
    console.log(e)
    if (e.keyCode === 32) {
      setSpacePressed(true);
      let start = new Date().getTime();
      if (time.current === 0) {
        time.current = start;
      }
    }

    if (e.keyCode === 82) {
      setResetPressed(true);
    }
  };

  //Function to handle key up event
  //Stores the time when the key is released in ms
  const handleKeyUp = (e) => {
    
    if (e.keyCode === 32) {
      setSpacePressed(false); 
      let startCheckTime = new Date().getTime();
      checkTime.current = startCheckTime;

      //Time when the key is released
      let end = new Date().getTime();

      //Time elpased between key press and release
      time.current = end - time.current;

      //Register Dit if the time elapsed is less than 200ms
      if (time.current <= 200) {
        setCode((prevState) => [...prevState, "."]);
        buffer.current += ".";
      }

      //Register Dah if the time elapsed is more than 200ms
      else {
        setCode((prevState) => [...prevState, "_"]);
        buffer.current += "_";
      }

      time.current = 0;
    }

    if (e.keyCode === 82) {
      setResetPressed(false);

      index.current = 0;
      buffer.current = "";
        setCode([]);
    }
  };

  console.log("Before entering second useEffect");
  useEffect(() => {
    /*The code inside runs every second
        to check if the code entered for the character 
        is correct or not*/

    console.log("Entered second useEffect");

    const interval = setInterval(() => {
      let currentTime = new Date().getTime();

      /*Checking if the diff b/w time when last code is entered
            and the current time is >= 800, so as to ensure that 
            the complete code for the character is entered or not*/
      if (checkTime.current && currentTime - checkTime.current >= 800) {
        checkTime.current = 0;
        if (
          legend[text.toUpperCase().split(" ").join("")[index.current]] ===
          buffer.current
        ) {
          //Checks wether if it is end of the text or not.
          if (index.current < text.split(" ").join("").length - 1) {
            console.log(text[index.current]);
            index.current += 1;
            buffer.current = "";
            if(text[index.current] === " "){
              setCode((prevState) => [...prevState, "  "]);
            }
          } 
          //If the entered code is correct
          else {
            index.current = 0;
            buffer.current = "";
            setCode([]);

            /** 
             * Slicing is done to ensure that the reference to the object
             * is changed, if assigning simply react would take it as the 
             * same previous object and won't cause the re-rendering of the
             * component.
            */
            var temp = object.slice();
            temp.map( item => {
              if( item.Item === text){
                item.Success = item.Success + 1;
                item.Occurence = item.Occurence + 1;
              }
            })
            setObject(temp);
            generateText();
            getObject(object)

          }
        } else {
          /*If the code entered for the current character
                is not correct than delete the entered code till than*/
          setCode(code.slice(0, code.length - buffer.current.length));
          buffer.current = "";
          
          /** 
             * Slicing is done to ensure that the reference to the object
             * is changed, if assigning simply react would take it as the 
             * same previous object and won't cause the re-rendering of the
             * component.
          */
          var temp = object.slice();
            temp.map( item => {
              if( item.Item === text){
                item.Failure = item.Failure + 1;
                item.Occurence = item.Occurence + 1;
              }
            })
          setObject(temp);
          generateText();
          getObject(object)
        }
      }
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, [text, code]);

  


  return (
    
      <>
      <CardContent>
      <ThemeProvider theme={TEXT}>
      <Typography className={classes.heading}>{text}</Typography>
      </ThemeProvider>
      <div className={classes.code_field}>
      <ThemeProvider theme={CODE}>
        <Typography className={classes.codeInput}>
            {code}
        </Typography>
      </ThemeProvider>
      </div>
      
      </CardContent>
      <div className={classes.keyboard}>
        <img className={spacePressed? classes.space_img_pressed :classes.space_img} src={spacePressed? spaceImgPressed :spaceImg}></img>
      </div>
      </> 
    
  );
}
