const legend = [
    {
        'Item': 'A',
        "Code": '._',
    },
    {
        'Item': 'B',
        "Code": '_...',
    },
    {
        'Item': 'C',
        "Code": '_._.',
    },
    {
        'Item': 'D',
        "Code": '_..',
    },
    {
        'Item': 'E',
        "Code": '.',
    },
    {
        'Item': 'F',
        "Code": '.._.',
    },
    {
        'Item': 'G',
        "Code": '__.',
    },
    {
        'Item': 'H',
        "Code": '....',
    },
    {
        'Item': 'I',
        "Code": '..',
    },
    {
        'Item': 'J',
        "Code": '.___',
    },
    {
        'Item': 'K',
        "Code": '_._',
    },
    {
        'Item': 'L',
        "Code": '._..',
    },
    {
        'Item': 'M',
        "Code": '__',
    },
    {
        'Item': 'N',
        "Code": '_.',
    },
    {
        'Item': 'O',
        "Code": '___',
    },
    {
        'Item': 'P',
        "Code": '.__.',
    },
    {
        'Item': 'Q',
        "Code": '__._',
    },
    {
        'Item': 'R',
        "Code": '._.',
    },
    {
        'Item': 'S',
        "Code": '...',
    },
    {
        'Item': 'T',
        "Code": '_',
    },
    {
        'Item': 'U',
        "Code": '.._',
    },
    {
        'Item': 'V',
        "Code": '..._',
    },
    {
        'Item': 'W',
        "Code": '.__',
    },
    {
        'Item': 'X',
        "Code": '_.._',
    },
    {
        'Item': 'Y',
        "Code": '_.__',
    },
    {
        'Item': 'Z',
        "Code": '__..',
    },
  ]

  export default legend;