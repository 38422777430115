import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import { red } from '@mui/material/colors';

const spaceImg = '/assets/spacebar_new.png';
const spaceImgPressed = '/assets/spacebar_new_pressed.png';
const resetImg = '/assets/reset_new.png';
const resetImgPressed = '/assets/reset_new_pressed.png';


const useStyles = makeStyles({
    heading:{
        // fontfamily: `'Rubik', sans-serif`,
        paddingTop: 20,
        paddingBottom: 10,
        fontSize: 38,
        fontWeight: '400',
        height: 110
    },

    code_field:{
        maxWidth: 700,
        minHeight: 50,
        paddingRight: '5%',
        paddingLeft: '5%',
        maxWidth: '700px',
        background:'black',
        color: 'white',
        borderRadius: 5,
        margin: 'auto',
        overflowWrap: 'break-word',
    },

    codeInput:{
        fontSize: '100px',
        lineHeight: '0.5em',
    },

    space_img:{
      aspectRatio: 'auto',
      maxWidth: '400px',
      width: '100%',
      //boxShadow: "0px 2px 10px white",
      verticalAlign: 'bottom'
    },

    space_img_pressed:{
      position: 'relative',
      aspectRatio: 'auto',
      maxWidth: '400px',
      width: '100%',
      //boxShadow: "0px 2px 10px white",
      verticalAlign: 'bottom'
    },

    reset_img:{
      maxWidth: '87px',
      width: '100%',
      verticalAlign: 'bottom'
    },

    keyboard:{
      display: 'inline-block',
      background: 'black',
    },

    
})

const THEME = createTheme({
    typography: {
    "fontFamily": `'VT323', monospace`,
     //"fontFamily": `"Rubik", sans-serif`,
     "fontSize" : 25,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
 });

export default function CodeBlock({text,generateText, resetCode}) {

  const index = useRef(0);
  const state = useRef();
  const time = useRef(0);
  const checkTime = useRef(0);
  const [code, setCode] = useState([]);
  const buffer = useRef("");
  const word_count = 2;
  const classes = useStyles();
  const [spacePressed, setSpacePressed] = useState(false);
  const [resetPressed, setResetPressed] = useState(false);


  const legend = {
    A: "._",
    B: "_...",
    C: "_._.",
    D: "_..",
    E: ".",
    F: ".._.",
    G: "__.",
    H: "....",
    I: "..",
    J: ".___",
    K: "_._",
    L: "._..",
    M: "__",
    N: "_.",
    O: "___",
    P: ".__.",
    Q: "__._",
    R: "._.",
    S: "...",
    T: "_",
    U: ".._",
    V: "..._",
    W: ".__",
    X: "_.._",
    Y: "_.__",
    Z: "__..",
  };


  //Function to handle key down event
  //Stores the time when the key is pressed in ms
  const handleKeyDown = (e) => {
    console.log(e)
    if (e.keyCode === 32) {
      setSpacePressed(true);
      let start = new Date().getTime();
      if (time.current === 0) {
        time.current = start;
      }
    }

    if (e.keyCode === 82) {
      setResetPressed(true);
    }
  };

  //Function to handle key up event
  //Stores the time when the key is released in ms
  const handleKeyUp = (e) => {
    
    if (e.keyCode === 32) {
      setSpacePressed(false); 
      let startCheckTime = new Date().getTime();
      checkTime.current = startCheckTime;

      //Time when the key is released
      let end = new Date().getTime();

      //Time elpased between key press and release
      time.current = end - time.current;

      //Register Dit if the time elapsed is less than 200ms
      if (time.current <= 200) {
        setCode((prevState) => [...prevState, "."]);
        buffer.current += ".";
      }

      //Register Dah if the time elapsed is more than 200ms
      else {
        setCode((prevState) => [...prevState, "_"]);
        buffer.current += "_";
      }

      time.current = 0;
    }

    if (e.keyCode === 82) {
      setResetPressed(false);

      index.current = 0;
      buffer.current = "";
      generateText();
        setCode([]);
    }
  };

  useEffect(() => {
    /*The code inside runs every second
        to check if the code entered for the character 
        is correct or not*/


    const interval = setInterval(() => {
      let currentTime = new Date().getTime();

      /*Checking if the diff b/w time when last code is entered
            and the current time is >= 800, so as to ensure that 
            the complete code for the character is entered or not*/
      
      if (checkTime.current && currentTime - checkTime.current >= 800) {

        console.log('Character now',text[index.current])
        
        console.log('Character now',text[index.current])
        console.log("Code matching",text[index.current],text[index.current].toUpperCase(), legend[text[index.current].toUpperCase()], buffer.current)
        checkTime.current = 0;
        if (
          legend[text[index.current].toUpperCase()] ===
          buffer.current
        ) {
          //Checks wether if it is end of the text or not.
          if (index.current < text.split(" ").join("").length - 1) {
            console.log('before update',text[index.current] , index.current, text.split(" ").join("").length - 1 );
            index.current += 1;   
            buffer.current = "";
            console.log('after update',text[index.current] , index.current, text.split(" ").join("").length - 1 );
          } else {
            console.log("Character correct", text[index.current])
            index.current = 0;
            buffer.current = "";
            generateText();
            setCode([]);
          }
        } else {
          console.log("Character incorrect", text[index.current])
          /*If the code entered for the current character
                is not correct than delete the entered code till than*/
          setCode(code.slice(0, code.length - buffer.current.length));
          buffer.current = "";
        }

        if(text[index.current] === ' '){
          console.log("space encountered before update", text[index.current] , index.current, text.split(" ").join("").length - 1 );
          index.current += 1;
          console.log("space encountered after update", text[index.current] , index.current, text.split(" ").join("").length - 1 );
          setCode((prevState) => [...prevState, "  "]);
        }
      }
    }, 800);

    return () => {
      clearInterval(interval);
    };
  }, [text, code]);

  //Registering event listener and calling the generateText()
  useEffect(() => {
    generateText();
    let keyDownEvent = document.addEventListener("keydown", handleKeyDown);
    let keyUpEvent = document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", keyDownEvent);
      document.removeEventListener("keyup", keyUpEvent);
    };
  }, []);

  console.log(text, code, text[index.current])
  return (


    <ThemeProvider theme={THEME}>

      <CardContent>
      <Typography className={classes.heading}>{text}</Typography>
      <div className={classes.code_field}>
        <Typography className={classes.codeInput}>
            {code}
        </Typography>
      </div>
      </CardContent>
      <div className={classes.keyboard}>
        <img className={spacePressed? classes.space_img_pressed :classes.space_img} src={spacePressed? spaceImgPressed :spaceImg}></img>
        <img className={classes.reset_img} src={resetPressed ? resetImgPressed: resetImg}></img>
      </div>
      
    </ThemeProvider>
  );
}
